



















import { defineComponent } from '@vue/composition-api';
import { mapGetters } from 'vuex';
import {
  HeatingSystem,
  HeatingSystemComponent,
  HeatingSystemMetadata,
  HeatingSystemMetadataResponse,
} from '@/features/core/store/page-modules/heating-systems-metadata/heating-systems-metadata.module';
import { getHeatingSystemsComponents } from '@/util/heating-systems';

export default defineComponent({
  data() {
    return {
      searchObject: '',
      heatingSystems: [] as HeatingSystem[],
    };
  },
  computed: {
    ...mapGetters(['openModal']),
    heatingSystemObjects(): HeatingSystem[] {
      return this.$store.getters.heatingSystems;
    },
    metadata(): HeatingSystemMetadataResponse[] {
      return this.$store.getters.heatingSystemsMetadata;
    },
    formData(): HeatingSystemMetadata {
      return this.$store.getters.formData;
    },
    filteredHeatingSystems(): HeatingSystem[] {
      // Filter heating systems using following criteria:
      // 1. Exclude selected/active heating system.
      // 2. Heating System Component does not have metadata.
      // 3. Heating System Component is the same type as selected/active component (i.e Kessel).
      return this.heatingSystemObjects
        .filter(
          (item) =>
            item.components.id !== this.activeHeatingSystem?.components.id &&
            this.hasSameComponentAsActive(item) &&
            (!item.metadata || item.metadata === null),
        )
        .map((item) => {
          return { ...item };
        });
    },
    heatingSystemsComponents(): HeatingSystemComponent[] {
      return this.$store.getters.heatingSystemsComponents;
    },
    activeHeatingSystem(): HeatingSystem | undefined {
      return this.$store.getters.activeHeatingSystem;
    },
    activeHeatingSystemComponent(): HeatingSystemComponent | undefined {
      return this.$store.getters.activeHeatingSystemComponent;
    },
  },
  watch: {
    searchObject(newValue) {
      if (newValue) {
        this.heatingSystems = this.filteredHeatingSystems.filter((item) => {
          return item.street?.toLocaleLowerCase().includes(newValue.toLocaleLowerCase());
        });
      } else {
        this.heatingSystems = this.filteredHeatingSystems;
      }
    },
    heatingSystems: {
      handler(val: HeatingSystem[]) {
        const selectedIdsToApplySettings = val.filter((item) => item.active === true).map((item) => item.components.id);
        this.$store.commit('setIdsToApplySettings', selectedIdsToApplySettings);
      },
      deep: true,
    },
  },
  mounted() {
    this.heatingSystems = this.filteredHeatingSystems;
  },
  methods: {
    hasSameComponentAsActive(heatingSystem: HeatingSystem): boolean {
      const activeComponent = this.heatingSystemsComponents.find((item) => item.active === true);
      if (!activeComponent?.label) {
        return false;
      }
      const components = Array.from(getHeatingSystemsComponents(heatingSystem));
      return components.some((item) => item.includes(activeComponent.label));
    },
    handleSelectAll() {
      this.heatingSystems.forEach((item) => {
        item.active = true;
      });
    },
    handleClearSelection() {
      this.heatingSystems.forEach((item) => {
        item.active = false;
      });
    },
  },
});
