










































































import { defineComponent } from '@vue/composition-api';
import { mapGetters } from 'vuex';
import { HeatingSystemSummary } from '@/features/domain-ui/heating-system-list-table/model';
import { getHeatingSystemsComponentsWithTypes, MetadataStatus } from '@/util/heating-systems';
import {
  HeatingSystem,
  HeatingSystemComponent,
  HeatingSystemMetadata,
  HeatingSystemMetadataKey,
  HeatingSystemMetadataResponse,
} from '@/features/core/store/page-modules/heating-systems-metadata/heating-systems-metadata.module';
import HeatingSystemsMetadataForm from './HeatingSystemsMetadataForm.vue';
import HeatingSystemsMetadataApplySettings from './HeatingSystemsMetadataApplySettings.vue';
import HeatingSystemsMetadataAudit from './HeatingSystemsMetadataAudit.vue';
import { isString } from 'lodash';
import Spinner from '@/components/clickables/Spinner.vue';

export default defineComponent({
  components: {
    HeatingSystemsMetadataForm,
    HeatingSystemsMetadataApplySettings,
    HeatingSystemsMetadataAudit,
    Spinner,
  },
  props: {
    heatingSystemsData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isLoadingMetadata: false,
      metadata: {} as HeatingSystemMetadataKey,
      filters: {
        searchInput: '',
        metadataStatus: null,
      },
      metadataStatusOptions: [
        {
          label: 'Pflichtangaben vollständig',
          value: MetadataStatus.MANDATORY_INFORMATION_COMPLETE,
        },
        {
          label: 'Pflichtangaben unvollständig',
          value: MetadataStatus.MANDATORY_INFORMATION_INCOMPLETE,
        },
      ],
      heatingSystemsMetadata: [] as HeatingSystemMetadataResponse[] | null,
    };
  },
  computed: {
    ...mapGetters(['openModal', 'openApplySettings', 'openAuditView']),
    heatingSystems(): HeatingSystem[] {
      return this.$store.getters.heatingSystems;
    },
    heatingSystemsComponents(): HeatingSystemComponent[] {
      return this.$store.getters.heatingSystemsComponents;
    },
    activeHeatingSystem(): HeatingSystem | undefined {
      return this.$store.getters.activeHeatingSystem;
    },
    activeHeatingSystemComponent(): HeatingSystemComponent | undefined {
      return this.$store.getters.activeHeatingSystemComponent;
    },
    filteredHeatingSystems(): HeatingSystem[] {
      if (this.filters.searchInput) {
        const result = this.heatingSystems.filter((heatingSystem: HeatingSystem) =>
          heatingSystem.street?.toLocaleLowerCase().includes(this.filters.searchInput.toLocaleLowerCase()),
        );
        return result.length > 0 ? result : this.heatingSystems;
      } else if (this.filters.metadataStatus) {
        return this.filterHeatingSystemsByMetadataStatus(this.filters.metadataStatus);
      } else {
        return this.heatingSystems;
      }
    },
    filteretedComponents(): HeatingSystemComponent[] {
      let components = this.heatingSystemsComponents;
      if (this.filters.searchInput) {
        const result = this.heatingSystemsComponents.filter((component: HeatingSystemComponent) =>
          component.label.toLocaleLowerCase().includes(this.filters.searchInput.toLocaleLowerCase()),
        );
        components = result.length > 0 ? result : this.heatingSystemsComponents;
      }
      return components.sort((a, b) => a.label.localeCompare(b.label));
    },
    formData(): HeatingSystemMetadata {
      return this.$store.getters.formData;
    },
  },
  watch: {
    heatingSystemsData(value) {
      const heatingSystems = value.map((item: HeatingSystemSummary) => {
        return {
          ...item,
          active: false,
        };
      });

      this.$store.commit('setHeatingSystems', heatingSystems);

      this.checkParams();
    },
  },
  methods: {
    closeModal() {
      this.$store.commit('openModal', false);
    },
    toggleAuditView() {
      if (!this.activeHeatingSystemComponent) {
        this.$store.dispatch('ADD_TOAST_MESSAGES', {
          messages: [{ text: 'Please select a component', class: 'info' }],
        });
      } else {
        this.$store.commit('toggleAuditView');
      }
    },
    async onHeatingSystemChange(id: string) {
      const activeHeatingSystem = this.heatingSystems.find((item) => item.components.id === id);

      // set active heating system
      this.heatingSystems.forEach((heatingSystem) => {
        heatingSystem.active = heatingSystem.components.id === id ? true : false;
      });

      // close "apply settings" section
      this.$store.commit('openApplySettings', false);

      // get active heating system components
      if (activeHeatingSystem) {
        const components = getHeatingSystemsComponentsWithTypes(activeHeatingSystem);
        const componentOptions = Array.from(components, ([key, value]) => ({
          value: key,
          label: value.component,
          active: false,
          type: value.type,
        }));
        this.$store.commit('setHeatingSystemsComponents', componentOptions);
      }

      // fetch updated HS metadata
      this.heatingSystemsMetadata = await this.fetchHeatingSystemsMetadata();
    },
    componentRequiredFields(component: string): string[] {
      switch (component) {
        case 'BOILER':
          return ['condensing'];
        case 'WATER_HEATING':
          return [
            'potable_water_target_temp',
            'potable_water_temperatuer_tolerance',
            'circulation_target_temp',
            'potable_water_temperatuer_tolerance',
            'type_of_pwh',
          ];
        //  no required fields for these
        case 'BUFFER':
        case 'HEATING_CIRCUIT':
        case 'TELE_HEATING':
          return [];
        default:
          return [];
      }
    },
    filterHeatingSystemsByMetadataStatus(status: string): HeatingSystem[] {
      if (this.heatingSystemsMetadata) {
        const completedMetadataIds = this.heatingSystemsMetadata.map((hs) => hs.heatingsystemId);
        if (status === 'MANDATORY_INFORMATION_COMPLETE') {
          return this.heatingSystems.filter((hs) => completedMetadataIds.includes(hs.components.id));
        } else if (status === 'MANDATORY_INFORMATION_INCOMPLETE') {
          return this.heatingSystems.filter((hs) => !completedMetadataIds.includes(hs.components.id));
        } else {
          return this.heatingSystems;
        }
      } else {
        return this.heatingSystems;
      }
    },
    async onHeatingSystemComponentChange(id: string) {
      this.heatingSystemsComponents.forEach((component) => {
        component.active = component.value === id ? true : false;
      });
      // close "apply settings" section
      this.$store.commit('openApplySettings', false);

      // check metadata for selected component
      const metadataObj = this.heatingSystemsMetadata?.find((metadata) => metadata.spotId === id);
      if (metadataObj) {
        this.metadata = metadataObj.metadata as HeatingSystemMetadataKey;
      }
    },
    async fetchHeatingSystemsMetadata(): Promise<HeatingSystemMetadataResponse[] | null> {
      try {
        this.isLoadingMetadata = true;
        const res = await this.$store.dispatch('getAllHeatingSystemMetadata');
        if (!res.ok) {
          return null;
        }

        const metadata = await res.json();
        this.$store.commit('setHeatingSystemMetadata', metadata);

        return metadata;
      } catch (error) {
        return null;
      } finally {
        this.isLoadingMetadata = false;
      }
    },
    // check if there was a heating system passed via params and select it.
    checkParams(): void {
      const heatingSystemId = this.$route.query['hsid'];
      if (heatingSystemId && isString(heatingSystemId)) {
        this.onHeatingSystemChange(heatingSystemId);
      }
    },
  },
});
