

































import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import query from '@/hsc-api/queries/AlertCustomerDefaultRulesQuery.gql';
import updateAlertDefaultMeasurementRulesMutation from '@/hsc-api/mutations/UpdateAlertDefaultMeasurementRulesMutation.gql';
import { APOLLO_CLIENT } from '@/features/core/container/model';
import {
  AlertCustomerDefaultRulesQuery,
  AlertCustomerDefaultRulesQueryVariables,
  AlertCustomerDefaultRulesQuery_alertCustomerDefaultRules_alertDefaultMeasurementRules,
} from '@/hsc-api/queries/__generated__/AlertCustomerDefaultRulesQuery';
import {
  UpdateAlertDefaultMeasurementRulesMutation,
  UpdateAlertDefaultMeasurementRulesMutationVariables,
} from '@/hsc-api/mutations/__generated__/UpdateAlertDefaultMeasurementRulesMutation';
import SettingsComponent from './components/SettingsComponent.vue';
import { AlertType, ComparisonValue, UpdateAlertDefaultMeasurementRulesInput } from '@/types/hsc-types';
import { isNull } from 'lodash';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import SettingsComponentHeader from './components/SettingsComponentHeader.vue';
type AlertDefaultMeasurementRule =
  AlertCustomerDefaultRulesQuery_alertCustomerDefaultRules_alertDefaultMeasurementRules;
@Component({
  components: { SettingsComponent, SettingsComponentHeader },
  apollo: {
    alertCustomerDefaultRules: {
      query,
      fetchPolicy: 'no-cache',
      client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
      variables(this: AlertDefaultSettingsPerComponent): AlertCustomerDefaultRulesQueryVariables {
        return {
          customerId: this.customerId,
        };
      },
    },
  },
})
export default class AlertDefaultSettingsPerComponent extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private alertCustomerDefaultRules?: AlertCustomerDefaultRulesQuery['alertCustomerDefaultRules'];
  private get loading(): boolean {
    return this.$apollo.queries.alertCustomerDefaultRules.loading;
  }

  private updateAlertType(
    value: AlertType | null,
    alertCustomerDefaultRuleId: string,
    measurementRuleId: string,
  ): void {
    this.updateDetails('alertType', value, alertCustomerDefaultRuleId, measurementRuleId);
  }

  private updateComparisonValueType(
    value: ComparisonValue | null,
    alertCustomerDefaultRuleId: string,
    measurementRuleId: string,
  ): void {
    this.updateDetails('comparisonValue', value, alertCustomerDefaultRuleId, measurementRuleId);
  }

  clearAlertSetting(value: AlertType | null, alertCustomerDefaultRuleId: string, measurementRuleId: string): void {
    this.updateDetails('comparisonValue', null, alertCustomerDefaultRuleId, measurementRuleId);
    this.updateDetails('toCompareValue', null, alertCustomerDefaultRuleId, measurementRuleId);
    this.updateDetails('alertType', value, alertCustomerDefaultRuleId, measurementRuleId);
  }

  private updateDetails(
    key: keyof AlertDefaultMeasurementRule,
    value: unknown,
    alertCustomerDefaultRuleId: string,
    measurementRuleId: string,
  ): void {
    if (!this.alertCustomerDefaultRules) return;
    this.alertCustomerDefaultRules = this.alertCustomerDefaultRules.map((alertCustomerDefaultRule) => ({
      ...alertCustomerDefaultRule,
      alertDefaultMeasurementRules:
        alertCustomerDefaultRule.id !== alertCustomerDefaultRuleId
          ? alertCustomerDefaultRule.alertDefaultMeasurementRules
          : alertCustomerDefaultRule.alertDefaultMeasurementRules.map((measurementRule) => ({
              ...measurementRule,
              [key]: measurementRule.id === measurementRuleId ? value : measurementRule[key],
            })),
    }));
  }

  private updateComparisonValue(comparisonValue: number, systemComponentTypeName: string, metricName: string): void {
    this.updateDetails('toCompareValue', +comparisonValue, systemComponentTypeName, metricName);
  }

  private async onUpdate(): Promise<unknown> {
    const input = this.getUpdateMutationInput();

    if (input === undefined || input.length === 0) return;

    const { data } = await this.$apollo.mutate<
      UpdateAlertDefaultMeasurementRulesMutation,
      UpdateAlertDefaultMeasurementRulesMutationVariables
    >({
      mutation: updateAlertDefaultMeasurementRulesMutation,
      client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
      variables: {
        input: input,
      },
    });
    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Einstellungen für Alarme gespeichert!', class: 'success' }],
      duration: 3000,
    });
    return data;
  }

  private getUpdateMutationInput(): UpdateAlertDefaultMeasurementRulesInput[] | undefined {
    if (!this.alertCustomerDefaultRules) return;
    const alertDefaultMeasurementRules = this.alertCustomerDefaultRules.flatMap((customerDefaultRule) =>
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      customerDefaultRule.alertDefaultMeasurementRules.map(({ __typename: _, ...measurementRule }) => ({
        ...measurementRule,
        alertCustomerDefaultRulesId: customerDefaultRule.id,
        customerId: this.customerId,
      })),
    );
    const input = alertDefaultMeasurementRules.filter(
      (measurementRule) =>
        !(
          isNull(measurementRule.alertType) &&
          isNull(measurementRule.comparisonValue && isNull(measurementRule.toCompareValue))
        ),
    );
    return input;
  }

  private async onSave(): Promise<void> {
    await this.onUpdate();
    this.$apollo.queries.alertCustomerDefaultRules.refresh();
  }
}
