












































































































































































import { defineComponent, ref } from '@vue/composition-api';
import query from './project-details.gql';
import projectQuery from './project.gql';
import { Option } from '@/features/ui/inputs/model';
import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import {
  EnterpriseProjectAuditEventType,
  EnterpriseProjectBuildingStatus,
  EnterpriseProjectStatus,
  TreeNodeClassification,
} from '@/types/iot-portal';
import { EnterpriseProjectUpdateProjectStatusMutationVariables } from './__generated__/EnterpriseProjectUpdateProjectStatusMutation';
import { AppEnterpriseProjectServiceProvidersQuery } from './__generated__/AppEnterpriseProjectServiceProvidersQuery';
import { GetProjectDetailsQuery } from './__generated__/GetProjectDetailsQuery';
import updateProjectStatusMutation from './update-project-status.gql';
import serviceProvidersQuery from './service-providers.gql';
import updateProjectBuildingsStatuses from './update-project-buildings-statuses.gql';
import createProjectLog from './create-project-log.gql';
import buildingsStatuses from './get-project-building-statuses.gql';
import GroupsMixin from '@/features/core/components/mixins/groups';
import ProjectBuildingEditConfirmationModal from './ProjectBuildingEditConfirmationModal.vue';
import EnterpriseProjectCreateModal from '@/features/app-enterprise-project/views/enterprise-project-create/EnterpriseProjectCreateModal.vue';
import { generateStandortlist } from './imports/create-xlsx';
import { UpdateProjectBuildingsStatusesVariables } from '@/features/app-enterprise-project/views/enterprise-project-details/__generated__/UpdateProjectBuildingsStatuses';
import Pagination from '@/features/ui/pagination/Pagination.global.vue';
import ConfirmationModalButton from '@/features/core/components/confirmation-modal/ConfirmationModalButton.vue';
import { mapActions } from 'vuex';
import getProjectData from '@/features/app-enterprise-project/views/enterprise-project-create/get-project-draft.gql';
import { transformDbObjectForState } from '@/features/core/store/page-modules/enterprise-project-create/create-module-helpers';
import {
  EnterpriseProjectCopyOption,
  LiegenshaftStatusMappings,
} from '@/features/app-enterprise-project/views/enterprise-project-details/project-details-constants';
import ProjectObjectsTable from '@/features/app-enterprise-project/views/enterprise-project-details/components/ProjectObjectsTable.vue';
import Panel from '@/features/ui/panel/Panel.global.vue';
import Cell from '@/features/ui/layout/Cell.global.vue';
import Flex from '@/features/ui/layout/Flex.global.vue';
import { EnterpriseProjectServiceType } from '@/features/app-enterprise-project/enterprise-project-constants';
import ButtonWithIcon from '@/components/clickables/Button.global.vue';
import { LogStandortlisteAuditVariables } from '@/features/app-enterprise-project/views/enterprise-project-details/__generated__/LogStandortlisteAudit';
import ArrowLeft from './arrow-left.svg?vue';
import ProjectCopyModal from '@/features/app-enterprise-project/views/enterprise-project-details/components/ProjectCopyModal.vue';
import FormMessage from '@/features/ui/form/FormMessage.global.vue';

const viewName = 'Detailsicht Projekt ';
interface EnterpriseProjectDetailsViewProp {
  projectReference: string;
}

type ServiceProvider = AppEnterpriseProjectServiceProvidersQuery['serviceProviders']['items'][number];
type ProjectObjectListItem = GetProjectDetailsQuery['projectDetails']['items'][number];

export default defineComponent<EnterpriseProjectDetailsViewProp, Record<string, unknown>, any, any>({
  name: 'EnterpriseProjectDetailsView',
  components: {
    Pagination,
    EnterpriseProjectCreateModal,
    ProjectBuildingEditConfirmationModal,
    ConfirmationModalButton,
    ProjectObjectsTable,
    Panel,
    Cell,
    Flex,
    ButtonWithIcon,
    ArrowLeft,
    FormMessage,
    ProjectCopyModal,
  },
  mixins: [PaginationQueryStringMixin, GroupsMixin],
  props: { projectReference: { type: String, required: true } },
  apollo: {
    projectDetails: {
      query,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          // This should be an array according to graphql types,
          // but it won't work so making it single value
          statuses: this.statusFilter ? this.statusFilter : undefined,
          search: this.search.length < 3 ? undefined : this.search,
          buildingType: this.buildingTypeFilter,
          projectReference: this.projectReference,
          skip: this.skip,
          take: this.take,
        };
      },
      result({ data }: any) {
        if (!data) {
          return;
        }

        for (const item of data.projectDetails.items) {
          if (item.externalId) {
            item.pdtpCCRNumber = item.externalId;
          }
          if (item.ccrReferenceId) {
            item.pdtpCCRNumber = item.ccrReferenceId;
          }
        }

        this.projectDetails = data.projectDetails;
      },
    },
    project: {
      query: projectQuery,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          projectReference: this.projectReference,
        };
      },
      result({ data }: any) {
        if (!data || !data.project?.parentProjectId) {
          return;
        }
        this.$store.commit('setProjectId', data.project.id);
        this.$store.commit('setProjectParentId', data.project.parentProjectId);
        if (data.project.projectCopyChanges) {
          this.$store.commit('setProjectCopyChangesId', data.project.projectCopyChanges.id);
        }
      },
    },
    // Get liegenshaft buildings statuses
    propertyListStatuses: {
      query: buildingsStatuses,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          projectReference: this.projectReference,
          buildingType: TreeNodeClassification.Liegenschaft,
        };
      },
      result({ data }: any) {
        if (!data || !data.propertyListStatuses || !data.propertyListStatuses.items) {
          return [];
        }

        this.propertyListStatuses = data.propertyListStatuses.items.map((item: any) => item.status);
      },
    },
    projectDraft: {
      query: getProjectData,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          projectReference: this.projectReference,
        };
      },
      result({ data }: any) {
        if (!data) {
          return;
        }

        const convertedObject = transformDbObjectForState(data?.projectDraft?.pageContent);
        this.$store.commit('setProjectDraftData', convertedObject);
        this.projectDraft = convertedObject;
      },
      error(error: any) {
        console.error('Error fetching project draft data:', error);
      },
    },
  },
  data() {
    return {
      projectDetails: undefined,
      project: undefined,
      projectDraft: undefined,
      propertyListStatuses: [],
      statusFilter: undefined,
      buildingTypeFilter: undefined,
      serviceProviderSelection: undefined,
      serviceProviders: undefined,
      editConfirmation: false,
      projectEditEnabled: false,
      viewName,
      search: '',
      tab: 'ProjectItems',
      statusMappings: LiegenshaftStatusMappings,
      buildingTypeFilterOptions: [
        { value: TreeNodeClassification.Liegenschaft, label: 'Liegenschaft' },
        { value: TreeNodeClassification.Gebaude, label: 'Gebaude' },
        { value: null, label: 'All' },
      ],
      saving: false,
    };
  },
  computed: {
    EnterpriseProjectStatus() {
      return EnterpriseProjectStatus;
    },
    EnterpriseProjectBuildingStatus() {
      return EnterpriseProjectBuildingStatus;
    },
    projectObjectList(): ProjectObjectListItem[] {
      const list: ProjectObjectListItem[] = this.projectDetails?.items ?? [];
      // Only include buildings
      return list.filter(({ buildingType }) => !!buildingType);
    },
    loading(): boolean {
      return (
        (this.$apollo.queries.projectDetails?.loading ||
          this.$apollo.queries.project?.loading ||
          this.$apollo.queries.me?.loading ||
          this.saving) ??
        true
      );
    },
    take(): number {
      return 12;
    },
    totalPages(): number {
      const pageCount = Math.ceil((this.projectDetails?.count ?? 0) / this.take);

      return pageCount;
    },
    skip(): number {
      return (this.currentPage - 1) * this.take;
    },
    statusOptions(): Option[] {
      return Array.from(this.statusMappings.values());
    },
    buildingTypeOptions(): Option[] {
      return this.buildingTypeFilterOptions;
    },
    projectEditDescription(): string {
      if (this.project?.editOptions?.editable) {
        if (this.project.editOptions.projectDownloaded || this.project.editOptions.buildingsCreated) {
          // Change Standorliste
          return 'Standortliste ändern';
        } else {
          // Resume Draft Project
          return 'Weiter mit Entwurf';
        }
      }

      // this will not be displayed
      return '';
    },
    shouldDisplayProjectEdit(): boolean {
      return this.project?.editOptions?.editable && this.project?.status !== EnterpriseProjectStatus.CANCELLED;
    },
    // Show "Download Standortliste" button if there are items, and they don't only have PROPERTY_CREATED, COMPLETED, or CANCELLED statuses
    showDownloadStandortlisteButton(): boolean {
      if (!this.projectDetails?.items || this.projectDetails.items.length === 0) {
        return false;
      }
      // Show the button if there's at least one status that is not PROPERTY_CREATED
      return this.propertyListStatuses.some(
        (status: string) => status !== EnterpriseProjectBuildingStatus.PROPERTY_CREATED,
      );
    },
    // Disable the "Download Standortliste" button if all statuses are either COMPLETED or CANCELLED
    disableDownloadStandortlisteButton(): boolean {
      if (!this.projectDetails?.items || this.projectDetails.items.length === 0) {
        return false;
      }
      // Disable the button if all statuses are either COMPLETED or CANCELLED
      return this.propertyListStatuses.every(
        (status: string) =>
          status === EnterpriseProjectBuildingStatus.COMPLETED || status === EnterpriseProjectBuildingStatus.CANCELLED,
      );
    },
    // Show "Standortliste Erstellen" button if at least one status is PROPERTY_CREATED
    showStandortlisteErstellenButton(): boolean {
      if (!this.projectDetails?.items || this.projectDetails.items.length === 0) {
        return false;
      }
      if (!this.shouldDisplayProjectEdit) {
        return false;
      }
      // Show the button if at least one status is PROPERTY_CREATED
      return this.propertyListStatuses.some(
        (status: string) => status === EnterpriseProjectBuildingStatus.PROPERTY_CREATED,
      );
    },
    treeNodesCreated(): boolean {
      return this.project?.editOptions?.treeNodesCreated;
    },
    getProjectTypeDisplayName(): string {
      if (this.project?.type) {
        // Get the display name of the project type
        const type = EnterpriseProjectCopyOption.find((option) => option.value === this.project.type);
        if (type) {
          return type.label;
        }
      }
      return '';
    },
  },
  watch: {
    async project() {
      if (!this.project) {
        return;
      }
    },
    async currentPage() {
      await this.$apollo.queries.projectDetails.refetch();
    },
  },
  methods: {
    ...mapActions({
      addToastMessages: 'ADD_TOAST_MESSAGES',
    }),
    getStatusLabel(status: EnterpriseProjectBuildingStatus): string {
      if (this.statusMappings.has(status)) {
        return this.statusMappings.get(status)!.label;
      }

      return status;
    },
    onBuildingTypeFilterUpdate(value: string): void {
      if (value) {
        this.buildingTypeFilter = value as TreeNodeClassification;

        return;
      }

      this.buildingTypeFilter = null;
    },
    // Function when standortlisten erstellen button is clicked
    async updateStatusesAndDownloadExport() {
      // Update property statuses to PROPERTY_LIST_FINAL
      await this.updateAllPropertyStatuses({
        input: { projectReference: this.projectReference, status: EnterpriseProjectBuildingStatus.PROPERTY_LIST_FINAL },
      });
      // Download the export
      await this.downloadStandortliste();
    },
    async updateAllPropertyStatuses(input: UpdateProjectBuildingsStatusesVariables): Promise<void> {
      this.saving = true;
      const { data } = await this.$apollo.mutate({
        mutation: updateProjectBuildingsStatuses,
        variables: { input: input.input },
      });

      if (!data) {
        throw new Error('Der Status kann nicht aktualisiert werden!');
      }

      // Refresh projectDetails
      this.refetchQueries();
      this.saving = false;
    },
    async createNewProjectAuditLog(): Promise<void> {
      this.saving = true;
      const input: LogStandortlisteAuditVariables = {
        projectId: this.project.id,
        auditType: EnterpriseProjectAuditEventType.DOWNLOAD_STANDORTLISTE,
      };
      const { errors } = await this.$apollo.mutate({
        mutation: createProjectLog,
        variables: { ...input },
      });
      if (errors) {
        throw new Error('Fehler beim Erstellen des Audit-Logs');
      }
      this.saving = false;
    },
    async updateProjectStatus(input: EnterpriseProjectUpdateProjectStatusMutationVariables): Promise<string> {
      const { data } = await this.$apollo.mutate({
        mutation: updateProjectStatusMutation,
        variables: { input: input.input },
      });

      if (!data) {
        throw new Error('Status kann nicht aktualisiert werden!');
      }
      // Refresh projectDetails
      this.refetchQueries();

      return data.customer;
    },
    async getServiceProviders(customerId: string): Promise<Option[] | null> {
      try {
        const { data } = await this.$apollo.query({
          query: serviceProvidersQuery,
          variables: {
            customerId,
          },
        });

        const serviceProviders: ServiceProvider[] = data.serviceProviders?.items ?? [];

        return serviceProviders.map(({ id, name }) => ({ label: name, value: id }));
      } catch (error) {
        console.error('Beim Abrufen von Dienstanbietern ist ein Fehler aufgetreten:', error);
        return null;
      }
    },
    refetchQueries(): void {
      this.$apollo.queries.projectDetails.refetch();
      this.$apollo.queries.project.refetch();
      this.$apollo.queries.propertyListStatuses.refetch();
    },
    goToProjectList() {
      this.$router.push('/enterprise-project/project-list');
    },
    // Filtering
    onStatusFilterUpdate(value: string): void {
      this.statusFilter = value;
    },
    // Tab
    updateTab(tab: string) {
      this.tab = tab;
    },
    async editProject(): Promise<void> {
      if (this.project?.editOptions?.editable) {
        if (this.project.editOptions.projectDownloaded || this.project.editOptions.buildingsCreated) {
          this.editConfirmation = true;
        } else {
          this.projectEditEnabled = true;
        }
      }
    },
    onConfirmProjectEdit() {
      this.projectEditEnabled = true;
    },
    onCloseProjectEditModal() {
      this.projectEditEnabled = false;
    },
    onProjectEditCancelled() {
      this.editConfirmation = false;
    },

    async downloadStandortliste() {
      const client = this.$apollo.getClient();
      const draftData = await client.query({
        query: getProjectData,
        fetchPolicy: 'no-cache',
        variables: { projectReference: this.project.projectReference },
      });

      const projectDraft = transformDbObjectForState(draftData?.data?.projectDraft?.pageContent);

      await generateStandortlist(this.project, client, projectDraft);
      // Create a new project audit log
      await this.createNewProjectAuditLog();
    },
    copyToClipboard() {
      navigator.clipboard
        .writeText(this.projectReference)
        .then(() => {
          // Optional: Notify the user that the text has been copied
          this.addToastMessages({
            messages: [{ text: 'Projektreferenz kopiert', class: 'success' }],
          });
        })
        .catch((err) => {
          console.error('Failed to copy text: ', err);
        });
    },
    // Cancel button
    async cancelProject() {
      await this.updateAllPropertyStatuses({
        input: { projectReference: this.projectReference, status: EnterpriseProjectBuildingStatus.CANCELLED },
      });
      await this.updateProjectStatus({
        input: { projectReference: this.projectReference, status: EnterpriseProjectStatus.CANCELLED },
      });
    },
    getMainServiceText(serviceType: EnterpriseProjectServiceType): string {
      // NB: GK_HUB_G should be excluded on FE
      if (serviceType === EnterpriseProjectServiceType.GK_HUB_L_GDSP_MOBILFUNK) {
        return 'GK_HUB_L_GDSP_MOBILFUNK';
      }
      if (serviceType === EnterpriseProjectServiceType.GK_HUB_L_CABLE) {
        return 'GK_HUB_L_Kabel';
      }
      return '';
    },
  },
});
