import { render, staticRenderFns } from "./PropertyEditProducts.vue?vue&type=template&id=1ef474a4&scoped=true&"
import script from "./PropertyEditProducts.vue?vue&type=script&lang=ts&"
export * from "./PropertyEditProducts.vue?vue&type=script&lang=ts&"
import style0 from "./PropertyEditProducts.vue?vue&type=style&index=0&id=1ef474a4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ef474a4",
  null
  
)

export default component.exports