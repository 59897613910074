













































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Mixins, Watch } from 'vue-property-decorator';
import Search from '@/features/core/components/tree-node-bar-control/Search.vue';
import { APOLLO_CLIENT } from '@/features/core/container/model';
import {
  HeatingSystemsWithPaginationQuery,
  HeatingSystemsWithPaginationQueryVariables,
} from '@/hsc-api/queries/__generated__/HeatingSystemsWithPaginationQuery';
import { StringProp } from '@/util/prop-decorators';
import { Column } from '@/features/ui/table/model';

import heatingSystemsQuery from '@/hsc-api/queries/HeatingSystemsWithPaginationQuery.gql';
import alertMeasurementRulesQuery from '@/hsc-api/queries/AlertMeasurementRulesQuery.gql';
import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { AlertMeasurementRulesQueryVariables } from '@/hsc-api/queries/__generated__/AlertMeasurementRulesQuery';
import SettingsMeasurementRuleHeader from './components/SettingsMeasurementRuleHeader.vue';
import SettingsMeasurementComponent from './components/SettingsMeasurementComponent.vue';
import {
  AlertMeasurementRules,
  HeatingSystem,
  HeatingSystemMeasurementGroup,
  SettingMeasurementComponent,
  UpdateAlertMesurementRule,
} from './model';
import {
  UpdateManyAlertMeasurementRuleMutation,
  UpdateManyAlertMeasurementRuleMutationVariables,
} from '@/hsc-api/mutations/__generated__/UpdateManyAlertMeasurementRuleMutation';

import {
  AlertCustomerDefaultRulesByCustomerAndSystemComponentTypeNameQuery,
  AlertCustomerDefaultRulesByCustomerAndSystemComponentTypeNameQueryVariables,
} from '@/hsc-api/queries/__generated__/AlertCustomerDefaultRulesByCustomerAndSystemComponentTypeNameQuery';
import getCustomerDefaultRules from '@/hsc-api/queries/AlertCustomerDefaultRulesByCustomerAndSystemComponentTypeNameQuery.gql';
import updateManyMeasurementRule from '@/hsc-api/mutations/UpdateManyAlertMeasurementRulesMutation.gql';
/**
 * with this type we are trying to create a special data structure which can be accessed very easy
 * this object will be like
 * {
 *    [heatingSystemId]: {
 *        [heatingSystemComponentId]: {
 *           systemComponentTypeName: string, measurementRules:[]
 *        }
 *    }
 * }
 * this object will be easy and fast accessed: this.object[heatingSystemId][heatingSystemComponentId].measurementRules
 */
type AlertMeasurementRulesByComponent = {
  [key: string]: { [key: string]: SettingMeasurementComponent };
};
import { AlertType, ComparisonValue } from '@/types/hsc-types';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { Action, RootAction } from '@/features/core/store';
import { cloneDeep } from 'lodash';

@Component({
  components: { Search, SettingsMeasurementComponent, SettingsMeasurementRuleHeader },
  apollo: {
    alertMeasurementRules: {
      client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
      fetchPolicy: 'no-cache',
      query: alertMeasurementRulesQuery,
      variables(this: AlertIndividualConfigurationOfComponents): AlertMeasurementRulesQueryVariables {
        return {
          heatingSystemIds: this.heatingSystemCollection?.items.map((item) => item.id) || [],
        };
      },
      skip(this: AlertIndividualConfigurationOfComponents): boolean {
        return !this.heatingSystemCollection || this.heatingSystemCollection.items.length === 0;
      },
      result(): void {
        this.alertMeasurementRulesByComponent = this.generateAlertsByComponentObject();
        this.copyAlertMeasurementRulesByComponent = cloneDeep(this.alertMeasurementRulesByComponent);
      },
    },
    heatingSystemCollection: {
      client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
      query: heatingSystemsQuery,
      fetchPolicy: 'no-cache',
      variables(this: AlertIndividualConfigurationOfComponents): HeatingSystemsWithPaginationQueryVariables {
        return { take: this.take, skip: this.skip, searchQuery: this.searchQuery, customerId: this.customerId };
      },
    },
  },
  data() {
    return {
      heatingSystemCollection: undefined,
      alertMeasurementRules: undefined,
      realCustomerId: undefined,
      alertMeasurementRulesByComponent: undefined,
      copyAlertMeasurementRulesByComponent: undefined,
    };
  },
})
export default class AlertIndividualConfigurationOfComponents extends Mixins(PaginationQueryStringMixin) {
  @StringProp(true)
  private readonly customerId!: string;

  private realCustomerId?: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly alertMeasurementRules?: AlertMeasurementRules[];
  private readonly heatingSystemCollection?: HeatingSystemsWithPaginationQuery['heatingSystemCollection'];
  private alertMeasurementRulesByComponent?: AlertMeasurementRulesByComponent;
  private copyAlertMeasurementRulesByComponent?: AlertMeasurementRulesByComponent;
  private searchQuery = '';

  private readonly columns: Column[] = [
    {
      name: 'address',
      accessor: (heatingSystem: HeatingSystem) => heatingSystem.economicUnit ?? '-',
      label: 'Anlage',
    },
    { name: 'rule', label: '' },
  ];

  protected get take(): number {
    return 10;
  }

  protected get count(): number {
    return this.heatingSystemCollection?.count ?? 0;
  }

  private onSearch(query: string): void {
    if (this.loading) {
      return;
    }

    this.searchQuery = query;
  }

  private get loading(): boolean {
    return this.$apollo.queries.heatingSystemCollection.loading || this.$apollo.queries.alertMeasurementRules.loading;
  }

  private updateAlertType(
    value: AlertType | null,
    heatingSystemId: string,
    measurementGroupId: string,
    heatingSystemMeasurementId: string,
  ): void {
    this.updateDetails('alertType', value, heatingSystemId, measurementGroupId, heatingSystemMeasurementId);
  }

  private updateComparisonValueType(
    value: ComparisonValue | null,
    heatingSystemId: string,
    measurementGroupId: string,
    heatingSystemMeasurementId: string,
  ): void {
    this.updateDetails('comparisonValue', value, heatingSystemId, measurementGroupId, heatingSystemMeasurementId);
  }

  private updateComparisonValue(
    comparisonValue: number,
    heatingSystemId: string,
    measurementGroupId: string,
    heatingSystemMeasurementId: string,
  ): void {
    this.updateDetails(
      'toCompareValue',
      +comparisonValue,
      heatingSystemId,
      measurementGroupId,
      heatingSystemMeasurementId,
    );
  }

  private setBackToHistoryPoints(
    heatingSystemId: HeatingSystem['id'],
    measurementGroupId: HeatingSystemMeasurementGroup['id'],
  ): void {
    if (this.alertMeasurementRulesByComponent) {
      try {
        const rulesByComponent = this.alertMeasurementRulesByComponent[heatingSystemId][measurementGroupId];
        if (rulesByComponent) {
          rulesByComponent.measurementRules = rulesByComponent.measurementRules.map((rule) => {
            const alertRule = this.alertMeasurementRules?.find(
              (oRule) => oRule.heatingSystemMeasurementId === rule.heatingSystemMeasurementId,
            );
            if (alertRule && alertRule.historyPoint.length > 0) {
              const point = alertRule.historyPoint[0];
              return {
                ...rule,
                toCompareValue: point.toCompareValue,
                alertType: point.alertType,
                comparisonValue: point.comparisonValue,
              };
            } else {
              return rule;
            }
          });
          this.alertMeasurementRulesByComponent[heatingSystemId][measurementGroupId] = rulesByComponent;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  }

  private async setDefaults(
    heatingSystemId: HeatingSystem['id'],
    measurementGroup: HeatingSystemMeasurementGroup,
  ): Promise<void> {
    const { data } = await this.$apollo.query<
      AlertCustomerDefaultRulesByCustomerAndSystemComponentTypeNameQuery,
      AlertCustomerDefaultRulesByCustomerAndSystemComponentTypeNameQueryVariables
    >({
      query: getCustomerDefaultRules,
      client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
      variables: {
        customerId: this.realCustomerId ?? this.customerId,
        systemComponentTypeName: measurementGroup.systemComponentTypeName,
      },
    });

    if (data?.alertCustomerDefaultRules) {
      const rules = data.alertCustomerDefaultRules.flatMap(
        (customerDefaultRule) => customerDefaultRule.alertDefaultMeasurementRules,
      );
      if (this.alertMeasurementRulesByComponent) {
        try {
          const rulesByComponent = this.alertMeasurementRulesByComponent[heatingSystemId][measurementGroup.id];
          if (rulesByComponent) {
            rulesByComponent.measurementRules = rulesByComponent.measurementRules.map((rule) => {
              return {
                ...rule,
                alertType: null,
                toCompareValue: null,
                comparisonValue: null,
              };
            });
            this.alertMeasurementRulesByComponent[heatingSystemId][measurementGroup.id] = rulesByComponent;
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    }
  }

  private getMutationInput(
    measurementGroupId?: HeatingSystemMeasurementGroup['id'],
  ): UpdateManyAlertMeasurementRuleMutationVariables['input'] {
    if (this.alertMeasurementRulesByComponent) {
      const object = measurementGroupId
        ? Object.values(this.alertMeasurementRulesByComponent).filter(
            (heatingSystem) => heatingSystem[measurementGroupId],
          )
        : Object.values(this.alertMeasurementRulesByComponent);

      return object.flatMap((component) =>
        Object.values(component).flatMap((settingComponent) =>
          settingComponent.measurementRules.map(({ measurementType: _, historyPoint: __, ...rest }) => ({
            ...rest,
            customerId: this.customerId,
          })),
        ),
      );
    } else {
      return [];
    }
  }

  private async performUpdate(measurementGroupId?: HeatingSystemMeasurementGroup['id']): Promise<void> {
    const input = this.getMutationInput(measurementGroupId);
    const { data } = await this.$apollo.mutate<
      UpdateManyAlertMeasurementRuleMutation,
      UpdateManyAlertMeasurementRuleMutationVariables
    >({
      mutation: updateManyMeasurementRule,
      variables: { input },
      client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
    });

    if (!data) {
      throw new Error('Der Vertrag konnte nicht hinzugefügt werden!');
    } else {
      this.ADD_TOAST_MESSAGES({
        messages: [{ text: 'Einstellungen für Alarme gespeichert!', class: 'success' }],
        duration: 3000,
      });

      this.$apollo.queries.alertMeasurementRules.refetch();
    }
  }

  private updateDetails(
    key: keyof UpdateAlertMesurementRule,
    value: unknown,
    heatingSystemId: string,
    measurementGroupId: string,
    heatingSystemMeasurementId: string,
  ): void {
    if (this.alertMeasurementRulesByComponent) {
      try {
        const rulesByComponent = this.alertMeasurementRulesByComponent[heatingSystemId][measurementGroupId];
        if (rulesByComponent) {
          rulesByComponent.measurementRules = rulesByComponent.measurementRules.map((measurementRule) => {
            if (measurementRule.heatingSystemMeasurementId === heatingSystemMeasurementId) {
              return { ...measurementRule, [key]: value };
            } else {
              return measurementRule;
            }
          });

          this.alertMeasurementRulesByComponent[heatingSystemId][measurementGroupId] = rulesByComponent;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  }

  private getSettingComponent(
    heatingSystem: HeatingSystem,
    measurementGroup: HeatingSystemMeasurementGroup,
  ): SettingMeasurementComponent {
    const empty = {
      measurementRules: [],
      systemComponentTypeName: measurementGroup.systemComponentTypeName,
    };
    try {
      const result = this.alertMeasurementRulesByComponent?.[heatingSystem.id]?.[measurementGroup.id];
      return result ? result : empty;
    } catch (e) {
      return empty;
    }
  }

  private getInitialSettingComponent(
    heatingSystem: HeatingSystem,
    measurementGroup: HeatingSystemMeasurementGroup,
  ): SettingMeasurementComponent {
    const empty = {
      measurementRules: [],
      systemComponentTypeName: measurementGroup.systemComponentTypeName,
    };
    try {
      const result = this.copyAlertMeasurementRulesByComponent?.[heatingSystem.id]?.[measurementGroup.id];
      return result ? result : empty;
    } catch (e) {
      return empty;
    }
  }

  private generateAlertsByComponentObject(): AlertMeasurementRulesByComponent | Record<string, unknown> {
    if (!this.heatingSystemCollection) return {};

    return this.heatingSystemCollection.items.reduce((previous, heatingSystem) => {
      previous[heatingSystem.id] = heatingSystem.heatingSystemMeasurementGroups.reduce((prev, measurementGroup) => {
        prev[measurementGroup.id] = this.getSettingsMeasurementComponent(measurementGroup);
        return prev;
      }, {} as AlertMeasurementRulesByComponent[string]);
      return previous;
    }, {} as AlertMeasurementRulesByComponent);
  }

  private getSettingsMeasurementComponent(
    measurementGroup: HeatingSystemMeasurementGroup,
  ): SettingMeasurementComponent {
    const measurements = measurementGroup.heatingSystemMeasurements;
    return {
      systemComponentTypeName: measurementGroup.groupName,
      measurementRules: measurements.map<UpdateAlertMesurementRule>((measurement) => ({
        heatingSystemMeasurementId: measurement.id,
        alertType: AlertType.NOOP,
        comparisonValue: null,
        toCompareValue: null,
        id: null,
        measurementType: measurement.measurementType,
        ...this.getMeasurementRule(measurement.id),
      })),
    };
  }

  private getMeasurementRule(
    heatingSystemMeasurementId: string,
  ): Omit<AlertMeasurementRules, '__typename'> | undefined {
    return this.alertMeasurementRules
      ?.map(({ __typename: _, ...rule }) => rule)
      .find((rule) => rule.heatingSystemMeasurementId === heatingSystemMeasurementId);
  }

  @Watch('heatingSystemCollection')
  private watchAlertRules(): void {
    if (this.heatingSystemCollection && this.heatingSystemCollection.count > 0) {
      const resolvedCustomentId = this.heatingSystemCollection.items.at(0)?.customerId;
      if (resolvedCustomentId) {
        this.realCustomerId = resolvedCustomentId;
        this.$emit('update:realCustomerId', resolvedCustomentId);
      }
    }
  }
}
