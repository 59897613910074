



















import { defineComponent } from '@vue/composition-api';
import { mapGetters } from 'vuex';
import { ORIGIN_ENDPOINT } from '@/env';
import { keycloak } from '@/keycloak';
import usersQuery from '@/features/app-heating-system/views/tree-node/settings/get-users.gql';
import { getUsers_users_items } from '@/features/app-heating-system/views/tree-node/settings/__generated__/getUsers';

type User = getUsers_users_items;

interface AuditLog {
  ID: string;
  userId: string;
  customerId: string;
  auditLogType: string;
  timestamp: string;
  thirdPartyName: string;
  changedValueName: string;
  oldValue: any;
  newValue: any;
  metadata: any;
}

export default defineComponent({
  data() {
    return {
      auditLogs: [] as AuditLog[],
      users: [] as User[],
      columns: [
        { label: 'Datum / Uhrzeit', field: 'timestamp' },
        { label: 'Feld', field: 'changedValueName' },
        { label: 'Vorheriger Eintrag', field: 'oldValue' },
        { label: 'Neuer Eintrag', field: 'newValue' },
        { label: 'User', field: 'user' },
      ],
      pageSize: 10,
      currentPage: 1,
      totalPages: 1,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['openModal']),
  },
  watch: {},
  mounted() {
    this.getData();
  },
  methods: {
    async getData(): Promise<void> {
      await this.getUsers();
      await this.getAuditLogs();
    },
    async getUsers(): Promise<void> {
      try {
        this.loading = true;
        const { data } = await this.$apollo.query({
          query: usersQuery,
          fetchPolicy: 'network-only',
          variables: {
            skip: 0,
            take: 500,
          },
        });
        this.users = data.users.items;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    getUser(id: string | null): string | null {
      if (!id) return null;

      const user = this.users.find((user: User) => user.id === id);
      if (!user) return null;

      return `${user.firstName} ${user.lastName}`;
    },
    async getAuditLogs(): Promise<void> {
      try {
        this.loading = true;
        const res = await fetch(`${ORIGIN_ENDPOINT}/api/v1/audit-log/audit-logs`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${keycloak?.token ?? ''}`,
          },
        });
        const data = res.ok ? await res.json() : null;
        this.auditLogs = data.auditLogs
          .filter((auditLog: AuditLog) => auditLog.auditLogType === 'update')
          .map((auditLog: AuditLog) => ({
            ...auditLog,
            timestamp: new Date(auditLog.timestamp).toLocaleString(),
            user: this.getUser(auditLog.userId),
          }));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
