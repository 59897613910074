





















































































































































































































































































































































































































import { defineComponent } from '@vue/composition-api';
import { mapState, mapActions } from 'vuex';
import FormField from '@/features/ui/form/FormField.global.vue';
import Flex from '@/features/ui/layout/Flex.global.vue';
import Cell from '@/features/ui/layout/Cell.global.vue';
import {
  aufzugUseCaseOptions,
  EnterpriseProjectObjectUseCase,
  heizungUseCaseOptions,
} from '@/features/app-enterprise-project/enterprise-project-constants';
import {
  InfrastructureVariantType,
  IWCloudType,
} from '@/features/core/store/page-modules/enterprise-project-create/create-module-types';

const tgaGroup = [
  EnterpriseProjectObjectUseCase.TGA_DIGITIZATION,
  EnterpriseProjectObjectUseCase.TGA_MONITORING,
  EnterpriseProjectObjectUseCase.TGA_INTEGRATION,
];

export default defineComponent<any, Record<string, unknown>, any, any>({
  components: { Cell, FormField, Flex },
  props: {
    busy: {
      type: Boolean,
      required: false,
      default: false,
    },
    dirty: {
      type: Boolean,
      required: false,
      default: false,
    },
    formData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tgaUseCases: tgaGroup,
    };
  },
  computed: {
    EnterpriseProjectObjectUseCase() {
      return EnterpriseProjectObjectUseCase;
    },
    ...mapState({
      buildingInformation: (state: any) => state.EnterprisePropertyEditModule.buildingInformation,
    }),
    infrastructureVariantOptions() {
      return Object.values(InfrastructureVariantType);
    },
    iwCloudOptions() {
      return Object.values(IWCloudType);
    },
  },
  methods: {
    // Function to validate the form
    validateForm(formData: any) {
      return formData.customerName && formData.customerNumber && this.validateEmail(formData.partnerName);
    },
    validateEmail(email: string) {
      // Regular expression pattern for email validation
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Test the email against the pattern
      return emailPattern.test(email);
    },
    heizungUseCaseOptions() {
      return heizungUseCaseOptions;
    },
    aufzugUseCaseOptions() {
      return aufzugUseCaseOptions;
    },
    isTGAGroup(useCaseType: EnterpriseProjectObjectUseCase) {
      return tgaGroup.includes(useCaseType);
    },
  },
});
