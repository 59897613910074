


























import { defineComponent } from '@vue/composition-api';
import Panel from '@/features/ui/panel/Panel.global.vue';
import { mapGetters, mapState } from 'vuex';
import { InputValidationMixin } from '@/features/core/components/mixins/input-validation';
import CreateBuildingsButton from '@/features/app-enterprise-project/views/enterprise-project-create/components/CreateBuildingsButton.vue';
import { ProjectBuilding } from '@/features/core/store/page-modules/enterprise-project-create/create-module-types';
import PropertyCommentAssociation from '@/features/app-enterprise-project/views/enterprise-project-create/components/comments/PropertyCommentAssociation.vue';
import PropertyComment from '@/features/app-enterprise-project/views/enterprise-project-create/components/comments/PropertyComment.vue';
import CommentsMainBuildingList from '@/features/app-enterprise-project/views/enterprise-project-create/components/comments/CommentsMainBuildingList.vue';

export default defineComponent<any, Record<string, unknown>, any, any>({
  components: {
    CommentsMainBuildingList,
    CreateBuildingsButton,
    Panel,
    PropertyCommentAssociation,
    PropertyComment,
  },
  mixins: [InputValidationMixin],
  props: {},
  data() {
    return {
      buildingFormData: null,
      showAssociations: true,
    };
  },
  computed: {
    ...mapGetters(['commentBuildingMap']),
    ...mapState({
      newProjectData: (state: any) => state.EnterpriseProjectCreateModule.newProjectData,
      buildings: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.buildings,
      activeStep1BuildingId: (state: any) => state.EnterpriseProjectCreateModule.activeStep1BuildingId,
      activeStep2BuildingId: (state: any) => state.EnterpriseProjectCreateModule.activeStep2BuildingId,
      saving: (state: any) => state.EnterpriseProjectCreateModule.saving,
      activeBuilding: (state: any) => {
        if (
          state.EnterpriseProjectCreateModule.newProjectData.buildings &&
          state.EnterpriseProjectCreateModule.newProjectData.buildings?.length > 0
        ) {
          return state.EnterpriseProjectCreateModule.newProjectData.buildings.find(
            (building: ProjectBuilding) =>
              building.buildingId === state.EnterpriseProjectCreateModule.activeStep1BuildingId,
          );
        }
      },
      allLiegenshaftInformationCompleted(state: any): boolean {
        return state.EnterpriseProjectCreateModule.allLiegenshaftInformationCompleted;
      },
    }),
  },
  methods: {
    updateBuildingFormData(formData: any) {
      this.buildingFormData = formData;
    },
    // We put the functions here so that we can call to submit of the form, from outside the component
    validateBuildingInformationForm() {
      if (!this.buildingFormData) return false;
      return (
        this.validateEmail(this.buildingFormData.email) &&
        this.buildingFormData.phoneNumber &&
        this.buildingFormData.firstName &&
        this.buildingFormData.surname &&
        this.buildingFormData.title
      );
    },
    submitBuildingForm() {
      // Access the form's method triggerSubmit via ref
      if (this.$refs.projectBuildingForm) {
        this.$refs.projectBuildingForm.triggerSubmit();
      }
    },
    passOnHideEvent() {
      this.$emit('hide');
    },
    refreshList() {
      this.showAssociations = false;
      this.$forceUpdate();
      setTimeout(() => {
        this.showAssociations = true;
      }, 100);
    },
  },
});
