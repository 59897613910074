

















































import { defineComponent, ref } from '@vue/composition-api';
import { mapState, mapActions, mapGetters } from 'vuex';
import WizardButton from '@/components/clickables/WizardButton.global.vue';
import InputCheckbox from '@/features/ui/inputs/InputCheckbox.global.vue';
import Form from '@/features/ui/form/Form.global.vue';
import FormField from '@/features/ui/form/FormField.global.vue';
import * as xlsx from 'xlsx';
import FormMessage from '@/features/ui/form/FormMessage.global.vue';
import ProjectBuildingImporterFeedback from '@/features/app-enterprise-project/views/enterprise-project-create/components/step1/ProjectBuildingImporterFeedback.vue';
import {
  convertMappeSheetToData,
  getBuildingImportInfo,
  convertCobraSheetToData,
  ProjectBuildingsUpload,
  BuildingImportFormat,
  ImportRowErrors,
} from '@/features/app-enterprise-project/views/enterprise-project-create/components/step1/building-importer-helpers';
import Pagination from '@/features/ui/pagination/Pagination.global.vue';
import { ProjectBuilding } from '@/features/core/store/page-modules/enterprise-project-create/create-module-types';

interface ProjectBuildingImporterData {
  importedBuildings: ProjectBuilding[];
}

export default defineComponent<any, Record<string, unknown>, ProjectBuildingImporterData, any, any>({
  components: {
    Pagination,
    WizardButton,
    InputCheckbox,
    Form,
    FormField,
    FormMessage,
    ProjectBuildingImporterFeedback,
  },
  props: {},
  setup() {
    // This is for the modal
    const importFeedbackModal = ref(null);

    const openModal = (): void => {
      if (!importFeedbackModal.value) {
        return;
      }
      (importFeedbackModal.value as any).show();
    };

    return {
      importFeedbackModal,
      openModal,
      confirm,
    };
  },
  data() {
    return {
      importedBuildings: [] as ProjectBuilding[],
      errors: [],
      currentPage: 1,
      columnsCount: 4,
      itemsPerColumn: 20,
    };
  },
  computed: {
    ...mapGetters(['isCopy', 'originalMainBuildingIds']),
    ...mapState({
      generalInformationCompleted: (state: any) => state.EnterpriseProjectCreateModule.generalInformationCompleted,
      // Only disable import when there are buildings already created and none of them are editable
      buildingsImportDisabled: (state: any) =>
        state.EnterpriseProjectCreateModule.buildingsCreated &&
        state.EnterpriseProjectCreateModule.editableBuildings.size == 0,
      buildings: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.buildings,
    }),
    columns(): number[] {
      return Array.from({ length: this.columnsCount }, (_, i) => i + 1);
    },
    itemsPerPage(): number {
      return this.columnsCount * this.itemsPerColumn;
    },
    totalPages(): number {
      return Math.ceil(this.buildings.length / this.itemsPerPage);
    },
  },
  methods: {
    ...mapActions(['ADD_TOAST_MESSAGES']),
    async uploadProjectFile({ file, label }: ProjectBuildingsUpload): Promise<void> {
      const buffer = await (file as File).arrayBuffer();

      // Read the Excel file
      const workbook = xlsx.read(buffer, { type: 'array' });

      if (!workbook.SheetNames.length) {
        this.ADD_TOAST_MESSAGES({
          messages: [{ text: 'No sheets found in the file.', class: 'error' }],
        });
        return;
      }
      // Assuming the data is in the first sheet
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Convert the sheet to JSON
      const data: any[] = xlsx.utils.sheet_to_json(worksheet, { header: 1 });

      const importInfo = getBuildingImportInfo(data);

      if (!importInfo) {
        this.ADD_TOAST_MESSAGES({
          messages: [
            {
              text: 'Das Datei-Importformat konnte nicht erkannt werden. Bitte verwenden Sie einen der unterstützten Importformattypen',
              class: 'error',
            },
          ],
        });
        return;
      }

      let importErrors: ImportRowErrors[] = [];
      let buildingList: { [key: string]: any }[] = [];

      if (importInfo.importFormat == BuildingImportFormat.MAPPE) {
        const { data: convertedData, errors } = await convertMappeSheetToData(data, importInfo.columnHeaderRow);
        buildingList = convertedData;
        importErrors = errors;
      } else if (importInfo.importFormat == BuildingImportFormat.COBRA) {
        const { data: convertedData, errors } = await convertCobraSheetToData(data, importInfo.columnHeaderRow);
        buildingList = convertedData;
        importErrors = errors;
      }

      if (importErrors.length > 0) {
        this.errors = importErrors;
        this.openModal();
        return;
      }

      if (this.buildings.length > 0) {
        if (!this.validateBuildingSet(this.buildings, buildingList)) {
          this.ADD_TOAST_MESSAGES({
            messages: [
              { text: 'The new file import has different buildings to already-created buildings', class: 'error' },
            ],
          });
          return;
        }
      }
      this.importedBuildings = buildingList;

      await this.$store.dispatch('saveNewBuildingData', this.importedBuildings);
    },
    // validate imported buildings to ensure that they do not have a new set of data
    validateBuildingSet(currentBuildings: ProjectBuilding[], importedBuildings: ProjectBuilding[]): boolean {
      const currentBuildingSet = new Set(currentBuildings.map((building) => this.getBuildingIdentifier(building)));
      const importedBuildingSet = new Set();
      let newBuildingIntroduced = false;

      for (const building of importedBuildings) {
        const identifier = this.getBuildingIdentifier(building);

        if (!currentBuildingSet.has(identifier)) {
          newBuildingIntroduced = true;
        }
        importedBuildingSet.add(identifier);
      }

      const buildingSetsIdentical = !newBuildingIntroduced && currentBuildingSet.size == importedBuildingSet.size;

      return buildingSetsIdentical;
    },
    getBuildingIdentifier(building: ProjectBuilding): string {
      return `${building.houseAddress}${building.houseAddressSuffix}${building.streetAddress}${building.postCodeAddress}${building.cityAddress}`;
    },
    getCurrentPageBuildingsForColumn(column: number): ProjectBuilding[] {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage + (column - 1) * this.itemsPerColumn;
      const endIndex = startIndex + this.itemsPerColumn;
      return this.buildings.slice(startIndex, endIndex);
    },
    canEditBuilding(building: ProjectBuilding): boolean {
      if (!this.isCopy) {
        return true;
      }
      return this.originalMainBuildingIds.includes(building.buildingId);
    },
  },
});
