



























import { defineComponent } from '@vue/composition-api';
import Panel from '@/features/ui/panel/Panel.global.vue';
import PropertyList from '@/features/app-enterprise-project/views/enterprise-project-create/components/step2/PropertyList.vue';
import { mapGetters, mapState } from 'vuex';
import { InputValidationMixin } from '@/features/core/components/mixins/input-validation';
import PropertyElevatorAssociation from '@/features/app-enterprise-project/views/enterprise-project-create/components/elevator-assignment/PropertyElevatorAssociation.vue';
import CreateBuildingsButton from '@/features/app-enterprise-project/views/enterprise-project-create/components/CreateBuildingsButton.vue';
import { ProjectBuilding } from '@/features/core/store/page-modules/enterprise-project-create/create-module-types';
import WizardButton from '@/components/clickables/WizardButton.global.vue';

export default defineComponent<any, Record<string, unknown>, any, any>({
  components: {
    WizardButton,
    CreateBuildingsButton,
    PropertyElevatorAssociation,
    PropertyList,
    Panel,
  },
  mixins: [InputValidationMixin],
  props: {},
  data() {
    return {
      buildingFormData: null,
    };
  },
  computed: {
    ...mapGetters(['mainBuildingsWithComments']),
    ...mapState({
      newProjectData: (state: any) => state.EnterpriseProjectCreateModule.newProjectData,
      buildings: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.buildings,
      activeStep1BuildingId: (state: any) => state.EnterpriseProjectCreateModule.activeStep1BuildingId,
      activeStep2BuildingId: (state: any) => state.EnterpriseProjectCreateModule.activeStep2BuildingId,
      saving: (state: any) => state.EnterpriseProjectCreateModule.saving,
      activeBuilding: (state: any) => {
        if (
          state.EnterpriseProjectCreateModule.newProjectData.buildings &&
          state.EnterpriseProjectCreateModule.newProjectData.buildings?.length > 0
        ) {
          return state.EnterpriseProjectCreateModule.newProjectData.buildings.find(
            (building: ProjectBuilding) =>
              building.buildingId === state.EnterpriseProjectCreateModule.activeStep1BuildingId,
          );
        }
      },
      allLiegenshaftInformationCompleted(state: any): boolean {
        return state.EnterpriseProjectCreateModule.allLiegenshaftInformationCompleted;
      },
    }),
  },
  methods: {
    goToStep(step: string) {
      this.$store.dispatch('setUpdateTab', step);
      if (step !== 'step1') {
        this.$store.dispatch('saveToDB', { apolloClient: this.$apollo, finalSave: false });
      }
    },
    updateBuildingFormData(formData: any) {
      this.buildingFormData = formData;
    },
    // We put the functions here so that we can call to submit of the form, from outside the component
    validateBuildingInformationForm() {
      if (!this.buildingFormData) return false;
      return (
        this.validateEmail(this.buildingFormData.email) &&
        this.buildingFormData.phoneNumber &&
        this.buildingFormData.firstName &&
        this.buildingFormData.surname &&
        this.buildingFormData.title
      );
    },
    submitBuildingForm() {
      // Access the form's method triggerSubmit via ref
      if (this.$refs.projectBuildingForm) {
        this.$refs.projectBuildingForm.triggerSubmit();
      }
    },
    passOnHideEvent() {
      this.$emit('hide');
    },
    goToCommentSection() {
      this.$store.dispatch('setUpdateTab', 'comments');
      this.$store.dispatch('saveToDB', { apolloClient: this.$apollo, finalSave: false });
    },
  },
});
