














import {
  AppCustomerViewQuery,
  AppCustomerViewQuery_customers_items,
} from '@/features/app-customer/views/customer/__generated__/AppCustomerViewQuery';
import { APOLLO_CLIENT } from '@/features/core/container/model';
import { defineComponent } from '@vue/composition-api';
import query from '@/features/app-customer/views/customer/view.gql';

type Customer = AppCustomerViewQuery_customers_items;

export default defineComponent({
  data() {
    return {
      isLoading: false,
      selectedCustomer: null,
      customers: [] as AppCustomerViewQuery['customers']['items'],
    };
  },
  watch: {
    selectedCustomer(customer: Customer) {
      this.$emit('customer-updated', customer);
    },
  },
  mounted() {
    this.fetchCustomers();
  },
  methods: {
    async fetchCustomers(): Promise<void> {
      try {
        this.isLoading = true;
        const { data } = await this.$apollo.query<AppCustomerViewQuery>({
          client: APOLLO_CLIENT.PORTAL_CLIENT,
          query,
        });
        this.customers = data.customers.items;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
