



















































































import { EneriqTypesMixin } from '@/features/app-heating-system/components/mixins/measurements/eneriq-types-mixin';
import { CoreTreeNodeBarControlTreeNodeFragment } from '@/features/core/components/tree-node-bar-control/__generated__/CoreTreeNodeBarControlTreeNodeFragment';
import { defineComponent } from '@vue/composition-api';
import heatingSystemsListQuery from '@/hsc-api/queries/HeatingSystemCollectionWithPaginationQuery.gql';
import { APOLLO_CLIENT } from '@/features/core/container/model';
import {
  HeatingSystemCollectionWithPaginationQuery,
  HeatingSystemCollectionWithPaginationQuery_heatingSystemsList_items,
} from '@/hsc-api/queries/__generated__/HeatingSystemCollectionWithPaginationQuery';
import alertHeatingSystemSummariesQuery from '@/hsc-api/queries/AlertTicketsSummaryByHeatingSystemIdQuery.gql';
import {
  AlertHeatingSystemSummaries,
  AlertHeatingSystemSummaries_statuses,
  AlertHeatingSystemSummariesVariables,
} from '@/hsc-api/queries/__generated__/AlertHeatingSystemSummaries';
import { HeatingSystemHealth } from '@/types/iot-portal';
import metricsQuery from '../spot-with-latest-metrics.gql';
import CustomerSelection from './CustomerSelection.vue';
import { AppCustomerViewQuery_customers_items } from '@/features/app-customer/views/customer/__generated__/AppCustomerViewQuery';
import {
  CoreTreeNodeBarControlPathQuery,
  CoreTreeNodeBarControlPathQueryVariables,
} from '@/features/core/components/tree-node-bar-control/__generated__/CoreTreeNodeBarControlPathQuery';
import customerTreeNodesQuery from '@/features/core/components/tree-node-bar-control/path.gql';

type HeatingSystem = HeatingSystemCollectionWithPaginationQuery_heatingSystemsList_items & {
  health?: string;
};

type TreeNode = CoreTreeNodeBarControlTreeNodeFragment & {
  isOpen?: boolean;
  heatingSystems: HeatingSystem[];
};

type Customer = AppCustomerViewQuery_customers_items;

interface ComponentType {
  value: string;
  label: string;
  active: boolean;
}

export default defineComponent({
  components: {
    CustomerSelection,
  },
  mixins: [EneriqTypesMixin],
  props: {
    searchQuery: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      isLoadingHeatingSystems: false,
      isLoadingMetrics: false,
      isLoadingSummaries: false,
      isLoadingTreeNodes: false,
      customerTreeNodes: [] as CoreTreeNodeBarControlPathQuery['treeNodes']['items'],
      filteredTreeNodes: [] as CoreTreeNodeBarControlPathQuery['treeNodes']['items'],
      expandedTreeNodes: [] as string[],
      heatingSystemsList: [] as HeatingSystem[],
      selectedCustomer: null as Customer | null,
      componentTypes: [
        {
          label: 'Kessel',
          value: 'BOILER',
          active: true,
        },
        {
          label: 'Heizkreis',
          value: 'HEATING_CIRCUIT',
          active: false,
        },
        {
          label: 'Warmwasserbereitung',
          value: 'WATER_HEATING',
          active: false,
        },
        {
          label: 'Pufferspeicher',
          value: 'TELE_HEATING',
          active: false,
        },
        {
          label: 'Gaszähler',
          value: 'GAS_METER',
          active: false,
        },
        {
          label: 'Stromzähler',
          value: 'ELECTRICITY_METER',
          active: false,
        },
        {
          label: 'Warmwasserzähler',
          value: 'COLD_WATER_METER',
          active: false,
        },
        {
          label: 'Kaltwasserzähler',
          value: 'WARM_WATER_METER',
          active: false,
        },
      ] as ComponentType[],
      skip: 0,
      take: 500,
    };
  },
  computed: {},
  watch: {
    searchQuery(val: string) {
      if (val.length > 0) {
        this.filteredTreeNodes = this.customerTreeNodes.map((treeNode) => ({
          ...treeNode,
          children: {
            __typename: treeNode.children.__typename,
            items: treeNode.children.items.filter((location) =>
              location.name.toLocaleLowerCase().includes(val.toLocaleLowerCase()),
            ),
          },
        }));
      } else {
        this.filteredTreeNodes = this.customerTreeNodes;
      }
    },
  },
  methods: {
    async onSelectLocation(item: TreeNode) {
      item.isOpen = !item.isOpen;

      if (item.isOpen) {
        // get heating systems for the selected object
        const heatingSystems = await this.fetchHeatingSystems(item.id);
        // get heating systems' statuses and attach
        const statuses = await this.fetchAlertSummariesByHeatingSystem(heatingSystems);
        heatingSystems.forEach((hs) => {
          const status = statuses.find((status) => status.id === hs.id)?.health as HeatingSystemHealth;
          hs.health = this.getHealthStateColour(status);
        });

        // TODO: fetch metrics for each HS, currently its "process heavy" and timesout, possibly find another solution.

        item.heatingSystems = heatingSystems;
      }
    },
    getComponentTypes(): void {
      const items = new Set(
        this.heatingSystemsList.flatMap((hs) =>
          hs.heatingSystemMeasurementGroups.map((measurementGroup) => measurementGroup.systemComponentTypeName),
        ),
      );

      this.componentTypes = Array.from(items).map((item, idx) => ({
        value: item,
        label: item,
        active: idx === 0 ? true : false,
      }));
    },
    getHealthStateColour(value: HeatingSystemHealth | undefined) {
      switch (value) {
        case HeatingSystemHealth.CRITICAL:
          return 'RED';
        case HeatingSystemHealth.DEGRADED:
          return 'YELLOW';
        case HeatingSystemHealth.OK:
          return 'GREEN';
        default:
          return 'GREEN';
      }
    },
    onSelectComponentType(type: ComponentType) {
      this.componentTypes.forEach((item) => (item.active = false));
      type.active = true;
    },
    async fetchAlertSummariesByHeatingSystem(
      heatingSystems: HeatingSystem[],
    ): Promise<AlertHeatingSystemSummaries_statuses[] | []> {
      try {
        this.isLoadingSummaries = true;
        const heatingSystemIds = heatingSystems.map((hs) => hs.id);

        const variables: AlertHeatingSystemSummariesVariables = { hscIds: heatingSystemIds };
        const { data: alertTicketsData } = await this.$apollo.query<AlertHeatingSystemSummaries>({
          query: alertHeatingSystemSummariesQuery,
          client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
          variables,
          fetchPolicy: 'no-cache',
        });

        return alertTicketsData?.statuses || [];
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return [];
      } finally {
        this.isLoadingSummaries = false;
      }
    },
    async fetchHeatingSystems(id: string): Promise<HeatingSystem[]> {
      try {
        this.isLoadingHeatingSystems = true;
        const { data } = await this.$apollo.query<HeatingSystemCollectionWithPaginationQuery>({
          client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
          query: heatingSystemsListQuery,
          variables: {
            customerIdOrSiteId: id,
            skip: this.skip,
            take: this.take,
          },
        });

        return data.heatingSystemsList.items.filter((item) => item.heatingSystemMeasurementGroups.length > 0);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return [];
      } finally {
        this.isLoadingHeatingSystems = false;
      }
    },
    async fetchMetrics(spotIds: string[]) {
      try {
        this.isLoadingMetrics = true;
        const { data } = await this.$apollo.query({
          client: APOLLO_CLIENT.PORTAL_CLIENT,
          query: metricsQuery,
          variables: {
            spotIds,
          },
        });
        return data;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        this.isLoadingMetrics = false;
      }
    },
    async fetchTreeNodesByCustomer(id: string) {
      try {
        this.isLoadingTreeNodes = true;

        const { data } = await this.$apollo.query<
          CoreTreeNodeBarControlPathQuery,
          CoreTreeNodeBarControlPathQueryVariables
        >({ query: customerTreeNodesQuery, variables: { id } });

        this.customerTreeNodes = data.treeNodes.items;
        this.filteredTreeNodes = this.customerTreeNodes;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        this.isLoadingTreeNodes = false;
      }
    },
    toggleTreeNodeExpansion(id: string) {
      const index = this.expandedTreeNodes.indexOf(id);
      if (index > -1) {
        this.expandedTreeNodes.splice(index, 1);
      } else {
        this.expandedTreeNodes.push(id);
      }
    },
    async onUpdateCustomer(customer: Customer): Promise<void> {
      this.selectedCustomer = customer;

      await this.fetchTreeNodesByCustomer(customer.id);
    },
  },
});
