




































import { defineComponent } from '@vue/composition-api';
import { mapState, mapActions, mapGetters } from 'vuex';
import InputCheckbox from '@/features/ui/inputs/InputCheckbox.global.vue';
import FormMessage from '@/features/ui/form/FormMessage.global.vue';
import WizardButton from '@/components/clickables/WizardButton.global.vue';
import { ProjectBuilding } from '@/features/core/store/page-modules/enterprise-project-create/create-module-types';

interface ProjectBuildingImporterData {
  importedBuildings: ProjectBuilding[];
}

export default defineComponent<any, Record<string, unknown>, ProjectBuildingImporterData, any, any>({
  components: { WizardButton, InputCheckbox, FormMessage },
  props: {},
  data() {
    return {
      importedBuildings: [] as ProjectBuilding[],
      showCheckList: true,
    };
  },
  computed: {
    ...mapGetters([
      'buildingListForStep1InformationCopy',
      'buildingInformationFormValid',
      'showPropertyInformationCopySelection',
    ]),
    ...mapState({
      buildingsImportCompleted: (state: any) => state.EnterpriseProjectCreateModule.buildingsImportCompleted,
      buildings: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.buildings,
      selectedBuildingsForContactInformation: (state: any) =>
        state.EnterpriseProjectCreateModule.selectedBuildingsForContactInformation,
      activeStep1BuildingId: (state: any) => {
        return state.EnterpriseProjectCreateModule.activeStep1BuildingId;
      },
    }),
  },
  watch: {
    buildings: {
      handler() {
        this.toggleCheckList();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['ADD_TOAST_MESSAGES']),
    onBuildingClick(event: MouseEvent, buildingId: number) {
      // Update the selectedBuildingsForContactInformation in the state
      this.$store.commit('setBuildingSelectionForContactInformation', {
        buildingId,
        selected: (event.target as HTMLInputElement).checked,
      });
    },
    toggleCheckList() {
      this.showCheckList = false;
      this.showCheckList = true;
    },
    selectAllBuildings() {
      this.buildingListForStep1InformationCopy.forEach((building: ProjectBuilding) => {
        // Update the selectedBuildingsForContactInformation in the state
        this.$store.commit('setBuildingSelectionForContactInformation', {
          buildingId: building.buildingId,
          selected: true,
        });
      });
      this.toggleCheckList();
    },
    deselectAllBuildings() {
      this.buildingListForStep1InformationCopy.forEach((building: ProjectBuilding) => {
        this.$store.commit('setBuildingSelectionForContactInformation', {
          buildingId: building.buildingId,
          selected: false,
        });
      });
      this.toggleCheckList();
    },
    setShowPropertyInformationCopySelection() {
      this.$store.commit('setShowPropertyInformationCopySelection', !this.showPropertyInformationCopySelection);
    },
    submitBuildingForm() {
      this.$emit('save-form');
    },
  },
});
