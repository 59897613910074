














import { defineComponent } from '@vue/composition-api';
import { mapActions, mapGetters, mapState } from 'vuex';
import WizardButton from '@/components/clickables/WizardButton.global.vue';
import InputTextarea from '@/features/ui/inputs/InputTextarea.global.vue';
import { ProjectBuilding } from '@/features/core/store/page-modules/enterprise-project-create/create-module-types';

export default defineComponent({
  components: {
    WizardButton,
    InputTextarea,
  },
  props: {},
  data() {
    return {
      comment: '',
    };
  },
  computed: {
    ...mapGetters([]),
    ...mapState({
      buildings: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.buildings,
      activeStep2BuildingId: (state: any) => state.EnterpriseProjectCreateModule.activeStep2BuildingId,
      commentTabActiveBuildingId: (state: any) => state.EnterpriseProjectCreateModule.commentTabActiveBuildingId,
    }),
  },
  watch: {
    commentTabActiveBuildingId: {
      handler() {
        this.comment = this.buildings.find(
          (building: ProjectBuilding) => building.buildingId === this.commentTabActiveBuildingId,
        )?.comment;
      },
      deep: true,
    },
  },
  async created() {
    this.comment = this.buildings.find(
      (building: ProjectBuilding) => building.buildingId === this.activeStep2BuildingId,
    )?.comment;
  },
  methods: {
    ...mapActions({
      addToastMessages: 'ADD_TOAST_MESSAGES',
    }),
    saveComment() {
      this.$store.commit('saveBuildingComment', this.comment);
      this.$store.dispatch('saveToDB', { apolloClient: this.$apollo, finalSave: false });
      this.addToastMessages({
        duration: 1000,
        messages: [{ text: 'Projekt wurde angelegt', class: 'success' }],
      });
    },
  },
});
