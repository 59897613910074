import {
  EnterpriseProjectObjectProduct,
  LiegenschaftProduct,
  LiegenschaftUseCase,
  ProjectBuilding,
} from '@/features/core/store/page-modules/enterprise-project-create/create-module-types';

function checkIfBuildingHasAUseCase(useCases: LiegenschaftUseCase[] | undefined): boolean {
  if (!useCases) return false;
  for (let i = 0; i < useCases.length; i++) {
    if (useCases[i].checked !== undefined) {
      if (useCases[i].checked) {
        return true;
      }
    } else {
      if (useCases[i].useCaseType !== null) {
        return true;
      }
    }
  }
  return false;
}

function checkIfBuildingHasAProduct(building: ProjectBuilding): boolean {
  if (!building.products) return false;
  // Check if the building has a main service selected
  if (!building.mainService) {
    return false;
  }
  // Check that an infrastructure product is selected
  const infrastructureProduct = building.products.find(
    (product) => product.productType === EnterpriseProjectObjectProduct.INFRASTRUCTURE_VARIANT,
  );
  if (!infrastructureProduct) {
    return false;
  }
  // Check that a product is selected
  if (!infrastructureProduct.option) {
    return false;
  }
  return true;
}

// We need to check whether all liegenshaft buildings have at least one product or use case selected
export function doesBuildingHaveInfo(building: ProjectBuilding): boolean {
  if (building.liegenshaft) {
    if (!building.products && !building.useCases) {
      return false;
    }
    if (!checkIfBuildingHasAProduct(building)) {
      return false;
    }
  }
  return true;
}
