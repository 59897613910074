




































import { defineComponent, ref } from '@vue/composition-api';
import WizardButton from '@/components/clickables/WizardButton.global.vue';
import InputSelect from '@/features/ui/inputs/InputSelect.global.vue';
import { EnterpriseProjectCopyOption } from '@/features/app-enterprise-project/views/enterprise-project-details/project-details-constants';
import Flex from '@/features/ui/layout/Flex.global.vue';
import ButtonWithIcon from '@/components/clickables/Button.global.vue';
import { mapActions } from 'vuex';
import copyProject from '@/features/app-enterprise-project/views/enterprise-project-details/copy-project.gql';

export default defineComponent({
  name: 'ConfirmationModalButton',
  components: {
    Flex,
    InputSelect,
    WizardButton,
    ButtonWithIcon,
  },
  props: {
    projectReference: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const visible = ref(false);
    const modal = ref(null);

    const show = (): void => {
      visible.value = true;
      emit('show');
    };

    const hide = (): void => {
      visible.value = false;
      emit('hide');
    };

    const confirm = (): void => {
      visible.value = false;
      emit('confirm');
    };

    return {
      visible,
      modal,
      show,
      hide,
      confirm,
    };
  },
  data() {
    return {
      copyType: null,
      copyOptions: EnterpriseProjectCopyOption,
      saving: false,
    };
  },
  methods: {
    ...mapActions({
      addToastMessages: 'ADD_TOAST_MESSAGES',
    }),
    async copyProject() {
      this.saving = true;
      const { data } = await this.$apollo.mutate({
        mutation: copyProject,
        variables: {
          projectReference: this.projectReference,
          newProjectType: this.copyType,
        },
      });
      await this.addToastMessages({
        duration: 2000,
        messages: [{ text: 'Projekt erfolgreich kopiert', type: 'success' }],
      });
      this.hide();
      this.saving = false;
      // Clear state for create flow
      this.$store.commit('clearState');
      // Navigate to the new project
      if (data.copyProject?.projectReference) {
        await this.$router.push({
          name: 'AppEnterpriseProject/ProjectDetails',
          params: {
            projectReference: data.copyProject?.projectReference,
          },
        });
      }
    },
  },
});
