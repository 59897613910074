// This array list the headings of the Mappe Excel file that is
// imported in the first step of the enterprise project creation process.
// The headings are in order
export const TemplateHeadingRow = [
  'ObjektID',
  'Straße',
  'HNr',
  'Adresszusatz',
  'PLZ',
  'Ort',
  'VBereich',
  'Objekttyp',
  'Versorgungstyp',
  'Frequenz',
  'Produktgruppe',
  'KAI_Verfügbarkeit',
  'KIP_im_Objekt',
  'Vertragszustand',
  'MaxWE',
  'BestandWE',
  'Vertragsnummer',
  'Vertragsbeginn',
  'Laufzeit',
  'Vertragsende',
  'Risikogruppe',
  'Zahlungszyklus',
  'PG_Vertrag',
  'KASR1',
  'KASR2',
  'KASR3',
  'KASR4',
  'Umlageklausel',
  'Service_VNR',
  'Service_Start',
  'Service_LZ',
  'Service_Ende',
  'Preis Servicepauschale',
  'ARPU inkl. Service',
  'Objektumsatz_Jahr',
  'ARPU',
  'Anzahl_REPO_Jahr',
  'Letzte_Rechnung',
  'TLA',
  'TLA_Name',
  'TLA_Straße',
  'TLA_HNr',
  'TLA_Adresszusatz',
  'TLA_PLZ',
  'TLA_Ort',
  'LLA',
  'Partner',
  'LLA_Name',
  'LLA_Straße',
  'LLA_HNr',
  'LLA_Adresszusatz',
  'LLA_PLZ',
  'LLA_Ort',
  'UEP_ID',
  'UEP_Status',
  'Objekte_angebunden',
  'MaxWE_angebunden',
  'BestandWE_angebunden',
  'UEP_Straße',
  'UEP_HNr',
  'UEP_Adresszusatz',
  'UEP_PLZ',
  'UEP_Ort',
  'UEP_Adresse_OK',
  'ENV',
  'Internet',
  'Phone',
  'Bundle',
  'PayTV',
  'International',
  'KDplus',
  'Digitaler_Empfang',
  'Privat_HD',
  'SUM_KIP',
  '1000_Mbits',
  '500_Mbits',
  '400_Mbits',
  '300_Mbits',
  '250_Mbits',
  '200_Mbits',
  '100_Mbits',
  '60_Mbits',
  '50_Mbits',
  '32_Mbits',
  '16_bis_31_Mbits',
  'kleiner_16_Mbits',
  'unbekannte_Bandbreite',
  'SUM_KD',
  'DSL_Kunden',
  'Mobile_Kunden',
  'Telem_VNr',
  'Telem_Start',
  'Telem_LZ',
  'Telem_Ende',
  'ZV_Internet_VNr',
  'ZV_Interne_Start',
  'ZV_Internet_LZ',
  'ZV_Internet_Ende',
  'MNV_Internet_Umsatz',
  'MNV_Internet_ARPU',
  'GK4_VNr',
  'GK4_Start',
  'GK4_LZ',
  'GK4_Ende',
  'GK4_Produkt',
  'pGF_VNr',
  'pGF_Status',
  'pGF_Produkt',
  'pGF_Auftrag',
  'pGF_Startdatum_Auftrag_zum',
  'VPKN',
  'INV_Kenner',
  'Vertrags_Status',
  'Umsatz_Jahr_SP',
  'WE_ENV',
  'TLA_Name',
  'Geo_Latitude',
  'Geo_Longitude',
  'id',
];

// Define the mapping from German to English for the Mappe format
export const ColumnMapping: { [key: string]: string } = {
  ObjektID: 'objectId',
  Straße: 'street',
  HNr: 'houseNumber',
  Adresszusatz: 'addressSupplement',
  PLZ: 'postalCode',
  Ort: 'city',
  VBereich: 'area',
  Objekttyp: 'objectType',
  Versorgungstyp: 'supplyType',
  Frequenz: 'frequency',
  pGF_Startdatum_Auftrag_zum: 'orderStartDate',
  VPKN: 'vpkn',
  INV_Kenner: 'invIdentifier',
  Vertrags_Status: 'contractStatus',
  Umsatz_Jahr_SP: 'revenueYear',
  WE_ENV: 'weEnv',
  TLA_Name: 'tlaName',
  Geo_Latitude: 'geoLatitude',
  Geo_Longitude: 'geoLongitude',
  id: 'id',
  MaxWE: 'numberOfApartments',
};

// This array list the headings of the Cobra Excel file that is
// imported in the first step of the enterprise project creation process.
// The headings are in order
export const CobraTemplateHeadingRow = [
  'Projektbezogene Objekt ID',
  'Vertrags-Nr.',
  'System',
  'PowerON Objektnummer',
  '',
  'Datum Export',
  'ÜP',
  'BGSWID',
  'ADS ID',
  'Straße',
  'Nr.',
  'Zusatz',
  'PLZ',
  'Ort',
  'Objekt Einheiten',
  'Vertrags Einheiten',
  'davon WE',
  'davon GE',
  'Ink WE aktuell',
  'Mandant',
  'Vertragspartner',
  'ARPU pro Monat(netto)',
  'Ausbau',
  'NE4 Status',
  'GS2',
  'GS4',
  'GPS Koordinate X',
  'GPS Koordinate Y',
];

// Define the mapping from German to English on the Cobra file format
export const CobraColumnMapping: { [key: string]: string } = {
  'Projektbezogene Objekt ID': 'objectId',
  Straße: 'street',
  'Nr.': 'houseNumber',
  Zusatz: 'addressSupplement',
  PLZ: 'postalCode',
  Ort: 'city',
  'GPS Koordinate Y': 'geoLatitude',
  'GPS Koordinate X': 'geoLongitude',
  'Objekt Einheiten': 'numberOfApartments',
};

// All columns that required in the import
export const requiredFields = [
  'street',
  'houseNumber',
  'numberOfApartments',
  'postalCode',
  'city',
  'geoLatitude',
  'geoLongitude',
];
