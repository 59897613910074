





































import { defineComponent } from '@vue/composition-api';
import { ORIGIN_ENDPOINT } from '@/env';
import usersQuery from './get-users.gql';
import { getUsers_users_items } from './__generated__/getUsers';
import { Column } from '@/features/ui/table/model';

interface AuditLog {
  ID: string;
  userId: string;
  customerId: string;
  auditLogType: string;
  timestamp: string;
  thirdPartyName: string;
  changedValueName: string;
  oldValue: any;
  newValue: any;
  metadata: any;
}

type User = getUsers_users_items;

export default defineComponent({
  props: {
    openModal: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      visible: false,
      sectionOptions: [
        { value: 'Allgemeine Einstellungen', label: 'Allgemeine Einstellungen' },
        { value: 'Einstellungen für Alarme nach Komponenten', label: 'Einstellungen für Alarme nach Komponenten' },
        { value: 'Empfänger für Alarme auswählen', label: 'Empfänger für Alarme auswählen' },
        { value: 'Indviduelle Konfiguration von Komponenten', label: 'Indviduelle Konfiguration von Komponenten' },
      ],
      selectedOption: '',
      auditLogs: [] as AuditLog[],
      users: [] as User[],
      columns: [
        { label: 'Datum / Uhrzeit', field: 'timestamp' },
        { label: 'Abschnitt', field: 'section', width: '280px' },
        { label: 'Feld', field: 'changedValueName' },
        { label: 'Vorheriger Eintrag', field: 'oldValue', width: '300px' },
        { label: 'Neuer Eintrag', field: 'newValue', width: '300px' },
        { label: 'User', field: 'user' },
      ] as Column[],
      pageSize: 10,
      currentPage: 1,
      totalPages: 1,
      loading: false,
    };
  },
  watch: {
    openModal: function () {
      this.visible = true;
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    handleClose() {
      this.visible = false;
    },
    async getData(): Promise<void> {
      await this.getUsers();
      await this.getAuditLogs();
    },
    async getUsers(): Promise<void> {
      try {
        this.loading = true;
        const { data } = await this.$apollo.query({
          query: usersQuery,
          fetchPolicy: 'network-only',
          variables: {
            skip: 0,
            take: 500,
          },
        });
        this.users = data.users.items;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async getAuditLogs(type?: string): Promise<void> {
      try {
        this.loading = true;
        const res = await fetch(`${ORIGIN_ENDPOINT}/api/v1/audit-log/audit-logs`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = res.ok ? await res.json() : null;
        this.auditLogs = data.auditLogs
          .filter((auditLog: AuditLog) => {
            return type
              ? auditLog.auditLogType === 'HEATING_SYSTEM_ALERTING' && auditLog.metadata?.section === type
              : auditLog.auditLogType === 'HEATING_SYSTEM_ALERTING';
          })
          .sort((a: AuditLog, b: AuditLog) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
          .map((auditLog: AuditLog) => ({
            ...auditLog,
            user: this.getUser(auditLog.userId),
            timestamp: new Date(auditLog.timestamp).toLocaleString(),
            section: auditLog.metadata?.section,
          }));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    getUser(id: string | null): string | null {
      if (!id) return null;

      const user = this.users.find((user: User) => user.id === id);
      if (!user) return null;

      return `${user.firstName} ${user.lastName}`;
    },
    onFilterChange(val: string) {
      this.selectedOption = val;
      this.getAuditLogs(val);
    },
  },
});
