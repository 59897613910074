var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"create-liegenschaften-modal",class:_vm.openModal ? 'open' : ''},[_c('div',{staticClass:"modal-content"},[_c('span',{staticClass:"close",on:{"click":function($event){_vm.openModal = false}}},[_vm._v("×")]),_c('h3',[_vm._v("Liegenschaften erstellen")]),_c('p',[_vm._v("Sind Sie sicher, dass Sie die Liegenschaften anlegen wollen?")]),_c('div',{staticClass:"actions"},[_c('WizardButton',{attrs:{"data-element-id":"app-enterprise-project-create-modal-close","disabled":_vm.saving},on:{"click":function($event){_vm.openModal = false}}},[_vm._v(" Abbrechen ")]),_c('WizardButton',{attrs:{"data-element-id":"app-enterprise-project-create-modal-confirm","busy":_vm.saving},on:{"click":_vm.saveProject}},[_vm._v(" Bestätigen ")])],1)])]),_c('div',{staticClass:"create-liegenschaften-modal",class:_vm.openChangesModal ? 'open' : ''},[_c('div',{staticClass:"modal-content"},[_c('span',{staticClass:"close",on:{"click":function($event){_vm.openChangesModal = false}}},[_vm._v("×")]),_c('h3',[_vm._v("Liegenschaften erstellen")]),_c('p',[_vm._v("Um die Änderung abzuschließen benötigen wir noch Informationen zu den Altprodukten.")]),_c('div',{staticClass:"product-select-section"},[_vm._m(0),_c('div',{staticClass:"product-row"},[_c('span',{staticClass:"product-label"},[_vm._v("Main Service")]),_c('InputSelect',{staticClass:"copy-type-select",attrs:{"reduce":function (ref) {
	var value = ref.value;

	return value;
},"options":_vm.mainServiceOptions,"data-element-id":"project-main-service-new"},model:{value:(_vm.newProductSelection.mainService),callback:function ($$v) {_vm.$set(_vm.newProductSelection, "mainService", $$v)},expression:"newProductSelection.mainService"}}),_c('span',{staticClass:"arrow-icon"},[_vm._v("→")]),_c('InputSelect',{staticClass:"copy-type-select",attrs:{"reduce":function (ref) {
	var value = ref.value;

	return value;
},"options":_vm.mainServiceOptions,"data-element-id":"project-main-service-old"},model:{value:(_vm.oldProductSelection.mainService),callback:function ($$v) {_vm.$set(_vm.oldProductSelection, "mainService", $$v)},expression:"oldProductSelection.mainService"}})],1),_c('div',{staticClass:"product-row"},[_c('span',{staticClass:"product-label"},[_vm._v("Infrastrukturvariante")]),_c('InputSelect',{staticClass:"copy-type-select",attrs:{"reduce":function (ref) {
	var value = ref.value;

	return value;
},"options":_vm.infrastructureOptions,"data-element-id":"project-infrastructure-new"},model:{value:(_vm.newProductSelection.infrastructureVariant),callback:function ($$v) {_vm.$set(_vm.newProductSelection, "infrastructureVariant", $$v)},expression:"newProductSelection.infrastructureVariant"}}),_c('span',{staticClass:"arrow-icon"},[_vm._v("→")]),_c('InputSelect',{staticClass:"copy-type-select",attrs:{"reduce":function (ref) {
	var value = ref.value;

	return value;
},"options":_vm.infrastructureOptions,"data-element-id":"project-infrastructure-old"},model:{value:(_vm.oldProductSelection.infrastructureVariant),callback:function ($$v) {_vm.$set(_vm.oldProductSelection, "infrastructureVariant", $$v)},expression:"oldProductSelection.infrastructureVariant"}})],1),_c('div',{staticClass:"product-row"},[_c('span',{staticClass:"product-label"},[_vm._v("Heizung")]),_c('InputSelect',{staticClass:"copy-type-select",attrs:{"reduce":function (ref) {
	var value = ref.value;

	return value;
},"options":_vm.heatingOptions,"data-element-id":"project-heizung-new"},model:{value:(_vm.newProductSelection.heating),callback:function ($$v) {_vm.$set(_vm.newProductSelection, "heating", $$v)},expression:"newProductSelection.heating"}}),_c('span',{staticClass:"arrow-icon"},[_vm._v("→")]),_c('InputSelect',{staticClass:"copy-type-select",attrs:{"reduce":function (ref) {
	var value = ref.value;

	return value;
},"options":_vm.heatingOptions,"data-element-id":"project-heizung-old"},model:{value:(_vm.oldProductSelection.heating),callback:function ($$v) {_vm.$set(_vm.oldProductSelection, "heating", $$v)},expression:"oldProductSelection.heating"}})],1),_c('div',{staticClass:"product-row"},[_c('span',{staticClass:"product-label"},[_vm._v("Aufzug")]),_c('InputSelect',{staticClass:"copy-type-select",attrs:{"reduce":function (ref) {
	var value = ref.value;

	return value;
},"options":_vm.elevatorOptions,"data-element-id":"project-elevator-new"},model:{value:(_vm.newProductSelection.elevator),callback:function ($$v) {_vm.$set(_vm.newProductSelection, "elevator", $$v)},expression:"newProductSelection.elevator"}}),_c('span',{staticClass:"arrow-icon"},[_vm._v("→")]),_c('InputSelect',{staticClass:"copy-type-select",attrs:{"reduce":function (ref) {
	var value = ref.value;

	return value;
},"options":_vm.elevatorOptions,"data-element-id":"project-elevator-old"},model:{value:(_vm.oldProductSelection.elevator),callback:function ($$v) {_vm.$set(_vm.oldProductSelection, "elevator", $$v)},expression:"oldProductSelection.elevator"}})],1)]),_c('div',{staticClass:"actions"},[_c('WizardButton',{attrs:{"data-element-id":"app-enterprise-project-copy-changes-modal-close","disabled":_vm.saving},on:{"click":_vm.saveProject}},[_vm._v(" Projekt abschließen ")])],1)])]),_c('div',{staticClass:"create-liegenschaften-modal",class:_vm.openInstallationModal ? 'open' : ''},[_c('div',{staticClass:"modal-content"},[_c('h3',[_c('storng',[_vm._v("Projekt abschließen")])],1),_c('p',[_vm._v("Müssen Installationsleistungen in Verbindung mit diesem Projekt erbracht werden?")]),_c('p',[_vm._v(" Bitte beachten Sie, dass Ihre Antwort Einfluss auf den weiterführenden Prozess haben und es bei Falscheingaben zu Verzörgerungen kommen kann. ")]),_c('div',{staticClass:"actions"},[_c('WizardButton',{attrs:{"data-element-id":"app-enterprise-project-installation-modal-no","disabled":_vm.saving},on:{"click":function($event){return _vm.handleInstallationResponse(false)}}},[_vm._v(" Nein ")]),_c('WizardButton',{attrs:{"data-element-id":"app-enterprise-project-installation-modal-yes","disabled":_vm.saving},on:{"click":function($event){return _vm.handleInstallationResponse(true)}}},[_vm._v(" Ja ")])],1)])]),_c('WizardButton',{staticClass:"btn-next",attrs:{"data-element-id":"app-enterprise-project-create-modal-submit","busy":_vm.saving || _vm.externalLoading,"disabled":_vm.buttonDisabled},on:{"click":_vm.handleConfirmModal}},[_vm._v(" LIEGENSCHAFTEN ERSTELLEN ")])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-table-header"},[_c('div',{staticClass:"product-label header-label bold"},[_vm._v("Produkt")]),_c('div',{staticClass:"new header-label bold"},[_vm._v("Neu")]),_c('div',{staticClass:"arrow-placeholder"}),_c('div',{staticClass:"old header-label bold"},[_vm._v("Alt")])])}]

export { render, staticRenderFns }